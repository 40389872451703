import React, { useState, useContext, useEffect } from "react"
import { Box, Input, Select } from "../components/Core/";
import { TinySwitch } from '../components/Core/Switch';
import { Col, Row, Container } from "react-bootstrap";
import { Form, Formik } from "formik"
import BigRoundButton from './../components/BigRoundButton';
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo/Seo";
import { navigate } from 'gatsby';
import GlobalContext from '../context/GlobalContext';
import API from './../api/api';
import { getProperty, getAssociativeArray, sanitizeTextForLineBreaks } from "../utils/helperFn";
import {
    createLeadContactBillingAddressPayload,
    extractLeadIdFromAPIResponse,
    extractContactIdFromAPIResponse,
    extractAddressIdFromAPIResponse,
} from '../utils/OdinOrderHelpers';
import TSAPI from '../api';
import { emailRegex } from "../utils/helperFn";
import { prepareProductForLeadUpdate } from "../utils/OdinOrderHelpers";
import { OdinProductSchema } from "../utils/OdinProductSchema";
import axios from 'axios';

const constructHearAboutUsPlaceholder = (hearAboutUsValue) => {
    switch (hearAboutUsValue) {
        case 'Other':
            return "Tell us a bit more..."
        default:
            return ""
    }
}

const RegisterBduk = (props) => {
    const [inContractField, setInContractField] = useState(false);
    const [isCreatingRecords, setIsCreatingRecords] = useState(false);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)
    const [phoneChecked, setPhoneChecked] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [marketingConsent, setMarketingConsent] = useState(false);
    const [switchingProviderAdditionalField, setSwitchingProviderAdditionalField] = useState(false);
    const [hearAboutUsAdditionalField, setHearAboutUsAdditionalField] = useState(false);
    const [validEmailAddress, setValidEmailAddress] = useState(true) // false
    const [emailChecked, setEmailChecked] = useState(true) // false
    const gContext = useContext(GlobalContext);
    const registrationData = gContext.goGetRegistrationData();
    let leadContactBillingAddress = []

    const data = useStaticQuery(graphql`
        query getResidentialPrivacyNotice {
            strapiPrivacyNotice {
                title
                text
            }
            allStrapiOrderJourney {
                nodes {
                    sales_status
                    title
                    subtitle_1
                    alternative_text
                    subtitle_2
                    subtitle_3
                    callback_form_title
                    callback_form_subtitle_1
                    callback_form_subtitle_2
                }
            }
        }
    `);

    const staticText = getAssociativeArray(data.allStrapiOrderJourney.nodes, 'sales_status');

    var salesStatus = registrationData?.searchPremiseResults && getProperty(registrationData?.searchPremiseResults?.properties, 'SalesStatus');

    const pageText = staticText[salesStatus ? salesStatus.toLowerCase() : 'default'];

    useEffect(() => {
        if (!gContext.goGetRegistrationData()?.personalData?.businessSwitch) {
            goSetBusinessSwitch(false)
        }
        if (gContext.goGetRegistrationData()?.personalData?.currentProvider?.value === 'Other') {
            setSwitchingProviderAdditionalField("Other")
        }
        if (gContext.goGetRegistrationData()?.personalData?.hearAboutUsValue?.value === 'Other') {
            setHearAboutUsAdditionalField("Other")
        }
        if (gContext.goGetRegistrationData()?.personalData?.inContractValue?.value === true) {
            setInContractField(true)
        }
    }, [])


    useEffect(() => {
        if (validPhoneNumber) {// && validEmailAddress

            const personalDataObj = {
                firstName: gContext.goGetRegistrationData()?.personalData?.firstName,
                lastName: gContext.goGetRegistrationData()?.personalData?.lastName,
                email: gContext.goGetRegistrationData()?.personalData?.email,
                phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
                companyName: gContext.goGetRegistrationData()?.personalData?.companyName,
                companyPosition: gContext.goGetRegistrationData()?.personalData?.companyPosition,
                marketingConsent
            }

            const payload = {
                ...gContext.goGetRegistrationData(),
                customerType: gContext.goGetRegistrationData()?.personalData?.businessSwitch ? 'BUSINESS' : 'RESIDENTIAL',
                dateOfConsent: new Date(),
                currentProvider: gContext.goGetRegistrationData()?.personalData?.currentProvider?.value,
                currentProviderOther: gContext.goGetRegistrationData()?.personalData?.currentProviderOther,
                Source: 'WEBSITE',
                offerId: gContext.goGetRegistrationData()?.offer?.id,
                personalData: {
                    ...gContext.goGetRegistrationData().personalData,
                    phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
                    inContractValue: gContext.goGetRegistrationData()?.personalData?.inContractValue?.value,
                    hearAboutUsValue: gContext.goGetRegistrationData()?.personalData?.hearAboutUsValue?.value,
                    marketingConsent
                }
            };

            TSAPI.post(
                `/CrmModule/v1.0/db/batch`,
                createLeadContactBillingAddressPayload(payload, payload.personalData)
            )
                .then((response) => {
                    const leadId = extractLeadIdFromAPIResponse(response.data.data);
                    const contactId = extractContactIdFromAPIResponse(response.data.data);
                    const addressId = extractAddressIdFromAPIResponse(response.data.data);

                    const newState = {
                        ...gContext.goGetRegistrationData(),
                        leadId,
                        contactId,
                        addressId,
                        personalDataObj,
                        checkoutStep: 1,
                    };

                    gContext.goSetRegistrationData(newState);

                    if (typeof window !== 'undefined' && window) {
                        localStorage.removeItem('utmData');
                    }
                    leadContactBillingAddress = response.data.data

                    return TSAPI.post(`/CrmModule/v1.0/db-associations/Lead/${leadId}?format=transformLevel2`,
                        prepareProductForLeadUpdate(gContext.goGetRegistrationData().selectedProduct)
                    )
                })
                .then(response => {
                    console.log('%c 3. Adding products to a lead success', 'color:limegreen', response)

                    /* 4. Set stage for register interest scenarios */
                    if (
                        gContext.goGetRegistrationData().searchPremiseResults.properties.SalesStatus === 'REGISTER_INTEREST' ||
                        gContext.goGetRegistrationData().searchPremiseResults.properties.SalesStatus === null
                    ) {
                        return TSAPI.put(`/CrmModule/v1.0/db/Lead/${extractLeadIdFromAPIResponse(leadContactBillingAddress)}`,
                            {
                                'entity': 'CrmModule:Lead',
                                'stageId': OdinProductSchema.stageIds.leadStageId
                            }
                        )
                    }
                })
                .then(() => {
                    if (
                        gContext.goGetRegistrationData()?.searchPremiseResults.properties.SalesStatus === 'ORDER' &&
                        gContext.goGetRegistrationData()?.searchPremiseResults.properties.IsBDUK === 'true'
                    ) {
                        navigate("/checkout?s=1", {
                            state: {
                                location: gContext.goGetRegistrationData()?.personalData?.businessSwitch ? 'business' : 'residential'
                            },
                        });
                    } else {
                        let bsNumbered =
                            gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.BuildStatus ?
                                gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.BuildStatus.split('-')[0] :
                                0;

                        bsNumbered = parseInt(bsNumbered)
                        let buildNumber = bsNumbered ?? 0;
                        let bs = buildNumber > 0 && buildNumber < 5 ? 1 
                                 : buildNumber >= 5 && buildNumber < 8 ? 2 
                                 : 3;
        
                        navigate(`/success-register-interest?bs=${bs}`);
                    }
                })
                .catch(() => {
                    setIsCreatingRecords(false);
                    // setEmailChecked(false)
                    // setValidEmailAddress(false)
                    setPhoneChecked(false)
                    setValidPhoneNumber(false)
                });
        };
    }, [validPhoneNumber]); // , validEmailAddress

    const goSetBusinessSwitch = (e) => {
        gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            personalData: {
                ...gContext.goGetRegistrationData()?.personalData,
                businessSwitch: e
            }
        })
        if (e === false) {
            gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                personalData: {
                    ...gContext.goGetRegistrationData()?.personalData,
                    companyPosition: undefined,
                    companyName: undefined,
                    utrNumber: undefined,
                    vat: undefined,
                }
            })
        }
    }

    const canNotContinue = () => {
        if (
            !gContext.goGetRegistrationData()?.personalData?.firstName ||
            !gContext.goGetRegistrationData()?.personalData?.lastName ||
            !gContext.goGetRegistrationData()?.personalData?.phoneNumber ||
            !gContext.goGetRegistrationData()?.personalData?.currentSpeed ||
            !gContext.goGetRegistrationData()?.personalData?.email ||
            !emailRegex.test(gContext.goGetRegistrationData()?.personalData?.email) ||
            (gContext.goGetRegistrationData()?.personalData?.businessSwitch && (
                !gContext.goGetRegistrationData()?.personalData?.companyPosition ||
                !gContext.goGetRegistrationData()?.personalData?.companyName ||
                !gContext.goGetRegistrationData()?.personalData?.utrNumber ||
                !gContext.goGetRegistrationData()?.personalData?.vat
            ))
        ) {
            return true;
        } else {
            return false;
        }
    };

    const checkPhone = () => {
        setIsCreatingRecords(true);
        const twilioAccountSid = process.env.GATSBY_TWILIO_ACCOUNT_SID;
        const twilioAuthToken = process.env.GATSBY_TWILIO_ACCOUNT_TOKEN;
        let phoneNumber = gContext.goGetRegistrationData()?.personalData?.phoneNumber;

        if (phoneNumber.indexOf('+44') !== -1) {
            phoneNumber = phoneNumber.replace('+44', '')
        }

        const url = `https://lookups.twilio.com/v2/PhoneNumbers/${'+44' + phoneNumber}`;

        const config = {
            auth: {
                username: twilioAccountSid,
                password: twilioAuthToken,
            }
        };

        axios
            .get(url, config)
            .then((response) => {

                gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    personalData: {
                        ...gContext.goGetRegistrationData().personalData,
                        phoneNumberValidated: response.data.phone_number
                    }
                })

                setValidPhoneNumber(response.data.valid)
                if (!response.data.valid) {
                    setIsCreatingRecords(false);
                }
                setPhoneChecked(true)
            })
            .catch((error) => {
                setValidPhoneNumber(false)
                setIsCreatingRecords(false);
                setPhoneChecked(true)
            });
    }

    const checkEmail = () => {

        API.post('/NotificationModule/v1.0/validation/email', {
            email: gContext.goGetRegistrationData().personalData?.email
        })
            .then((resp) => {
                const result = resp.data.data[0].body.result
                setEmailChecked(true)
                setValidEmailAddress(result == 'Invalid' ? false : true)
            })
            .catch((error) => {
                setValidEmailAddress(false)
                setEmailChecked(true)
                gContext.setAPIErrorMsg({
                    title: 'Email validation error',
                    message: error.response ? error.response?.data.message : error
                });
            });
    }

    return (
        <>
            <Seo page="register-bduk" />
            <Container className="mt-50 register-bduk">
                <Box mx="auto">
                    <Row className="justify-content-center">
                        <Col lg={12} xl={8}>
                            <Row>
                                <Col sm={12} className="mx-auto">
                                    <div className="mb-5 pb-2 mobile-justify">
                                        <h3>{getProperty(pageText, 'title')}</h3>
                                        <p>{getProperty(pageText, 'subtitle_1')}</p>
                                        {getProperty(pageText, 'subtitle_2') && <p>{getProperty(pageText, 'subtitle_2')}</p>}
                                    </div>
                                    <Formik
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        initialValues={{
                                            titleOptions: [
                                                { value: 'Dr', label: 'Dr' },
                                                { value: 'Lady', label: 'Lady' },
                                                { value: 'Lord', label: 'Lord' },
                                                { value: 'Miss', label: 'Miss' },
                                                { value: 'Mr', label: 'Mr' },
                                                { value: 'Ms', label: 'Ms' },
                                                { value: 'Mx', label: 'Mx' },
                                                { value: 'Prof', label: 'Prof' },
                                                { value: 'Rev', label: 'Rev' }
                                            ],
                                            hearAboutUsOptions: [
                                                { value: 'Installation Engineer', label: 'Installation Engineer' },
                                                { value: 'Recommended by a neighbour', label: 'Recommended by a neighbour' },
                                                { value: 'Leaflet/Letter', label: 'Leaflet/Letter' },
                                                { value: 'Facebook', label: 'Facebook' },
                                                { value: 'Instagram', label: 'Instagram' },
                                                { value: 'LinkedIn', label: 'LinkedIn' },
                                                { value: 'Email', label: 'Email' },
                                                { value: 'Billboard', label: 'Billboard' },
                                                { value: 'Radio', label: 'Radio' },
                                                { value: 'News Article', label: 'News Article' },
                                                { value: 'Other', label: 'Other' }
                                            ],
                                            inContractOptions: [
                                                { value: true, label: 'Yes' },
                                                { value: false, label: 'No' }
                                            ],
                                            switchingProviderOptions: [
                                                { value: "NONE", label: "None" },
                                                { value: "BT", label: "BT" },
                                                { value: "Sky", label: "Sky" },
                                                { value: "Virgin", label: "Virgin" },
                                                { value: "TalkTalk", label: "TalkTalk" },
                                                { value: "EE", label: "EE" },
                                                { value: "PlusNet", label: "PlusNet" },
                                                { value: "Post Office", label: "Post Office" },
                                                { value: "Shell", label: "Shell" },
                                                { value: "Three", label: "Three" },
                                                { value: "Vodafone", label: "Vodafone" },
                                                { value: "Other", label: "Other" },
                                            ]
                                        }}
                                        onSubmit={() => {
                                            setFormSubmitted(true)
                                            if (canNotContinue()) {
                                                return
                                            }
                                            checkPhone()
                                            // checkEmail()
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            handleSubmit,
                                            setFieldValue,
                                        }) => (

                                            <Form>
                                                <Row>
                                                    <Col sm={12} lg={2}>
                                                        <Box mb={4} className="position-relative">
                                                            <Select
                                                                autocomplete="off"
                                                                defaultValue=""
                                                                name="title"
                                                                className="reactSelect selectForm register-bduk-select"
                                                                options={values.titleOptions}
                                                                isSearchable={false}
                                                                placeholder="Title"
                                                                value={gContext.goGetRegistrationData()?.personalData?.title}
                                                                onChange={option => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            title: option
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                    <Col sm={12} lg={4}>
                                                        <Box mb={4} className="position-relative">
                                                            {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.firstName && <p className="invalid-label">Required field</p>}
                                                            <Input
                                                                type=""
                                                                name="firstName"
                                                                placeholder="First name"
                                                                value={gContext.goGetRegistrationData()?.personalData?.firstName}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            firstName: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.lastName && <p className="invalid-label">Required field</p>}
                                                            <Input
                                                                type=""
                                                                name="lastName"
                                                                placeholder="Last name"
                                                                value={gContext.goGetRegistrationData()?.personalData?.lastName}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            lastName: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            {formSubmitted && !emailRegex.test(gContext.goGetRegistrationData()?.personalData?.email) && <p className="invalid-label">Required field</p>}
                                                            {formSubmitted && !validEmailAddress && !canNotContinue() && emailChecked && <p className="invalid-label">Please enter a valid email address</p>}
                                                            <Input
                                                                type=""
                                                                name="email"
                                                                placeholder="Email"
                                                                value={gContext.goGetRegistrationData()?.personalData?.email}
                                                                onChange={e => {
                                                                    // setEmailChecked(false)
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            email: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.phoneNumber && <p className="invalid-label">Required field</p>}
                                                            {formSubmitted && !validPhoneNumber && !canNotContinue() && phoneChecked && <p className="invalid-label">Please enter a valid mobile number</p>}

                                                            <Input type=""
                                                                name="phoneNumber"
                                                                placeholder="Mobile Number"
                                                                value={gContext.goGetRegistrationData()?.personalData?.phoneNumber}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            phoneNumber: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.currentSpeed && <p className="invalid-label">Required field</p>}
                                                            <Input type=""
                                                                name="currentSpeed"
                                                                placeholder="Your current download speed (Mbps)"
                                                                value={gContext.goGetRegistrationData()?.personalData?.currentSpeed}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            currentSpeed: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                            <a
                                                                href="https://www.speedtest.net/"
                                                                target="_blank"
                                                            >
                                                                <span
                                                                    className='consent-info register-consent'
                                                                    style={{ color: 'rgb(0, 198, 102)', textDecoration: 'underline' }}
                                                                >
                                                                    Check your speed here - opens in a new tab
                                                                </span>
                                                            </a>
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <hr className="mb-5 borderless" />

                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            <Select
                                                                autocomplete="off"
                                                                defaultValue=""
                                                                name="currentProvider"
                                                                options={values.switchingProviderOptions}
                                                                className="reactSelect selectForm register-bduk-select"
                                                                isSearchable={false}
                                                                placeholder="Who is your current broadband service provider?"
                                                                value={gContext.goGetRegistrationData()?.personalData?.currentProvider}
                                                                onChange={option => {
                                                                    option.value === "Other" ? setSwitchingProviderAdditionalField(option.value) : setSwitchingProviderAdditionalField(false)
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            currentProvider: option,
                                                                            currentProviderOther: switchingProviderAdditionalField ? "" : gContext.goGetRegistrationData()?.personalData?.currentProviderOther
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>

                                                    <Col sm={12} lg={6} className={`mx-auto ${switchingProviderAdditionalField ? '' : ' other-providers-placeholder'}`}>
                                                        <Box mb={4} className="position-relative">
                                                            <Input type=""
                                                                name="currentProviderOther"
                                                                placeholder={switchingProviderAdditionalField ? "Enter provider's name" : ""}
                                                                style={{
                                                                    backgroundColor: switchingProviderAdditionalField ? '#ffffff' : '#efefef',
                                                                    opacity: switchingProviderAdditionalField ? '1' : '0.4'
                                                                }}
                                                                disabled={!switchingProviderAdditionalField}
                                                                value={gContext.goGetRegistrationData()?.personalData?.currentProviderOther}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            currentProviderOther: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <hr className="mb-5 borderless" />

                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            <Select
                                                                autocomplete="off"
                                                                name="inContractValue"
                                                                options={values.inContractOptions}
                                                                defaultValue=""
                                                                className="reactSelect selectForm register-bduk-select"
                                                                isSearchable={false}
                                                                placeholder="Are you in contract?"
                                                                value={gContext.goGetRegistrationData()?.personalData?.inContractValue}
                                                                onChange={option => {
                                                                    setInContractField(option.value)
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            inContractValue: option,
                                                                            numberOfMonthsLeft: inContractField ? "" : gContext.goGetRegistrationData()?.personalData?.numberOfMonthsLeft
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>

                                                    <Col sm={12} lg={6} className={`mx-auto ${switchingProviderAdditionalField ? '' : ' other-providers-placeholder'}`}>
                                                        <Box mb={4} className="position-relative">
                                                            <Input
                                                                type="text"
                                                                maxLength="2"
                                                                name="numberOfMonthsLeft"
                                                                placeholder={inContractField ? "How many months remaining?" : ""}
                                                                style={{
                                                                    backgroundColor: inContractField ? '#ffffff' : '#efefef',
                                                                    opacity: inContractField ? '1' : '0.4'
                                                                }}
                                                                disabled={!inContractField}
                                                                value={gContext.goGetRegistrationData()?.personalData?.numberOfMonthsLeft}
                                                                onChange={e => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        gContext.goSetRegistrationData({
                                                                            ...gContext.goGetRegistrationData(),
                                                                            personalData: {
                                                                                ...gContext.goGetRegistrationData()?.personalData,
                                                                                numberOfMonthsLeft: e.target.value
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Box mb={4} className="position-relative">
                                                            <Select
                                                                autocomplete="off"
                                                                name="hearAboutUsValue"
                                                                options={values.hearAboutUsOptions}
                                                                className="reactSelect selectForm register-bduk-select"
                                                                defaultValue={""}
                                                                isSearchable={false}
                                                                placeholder="How did you hear about us?"
                                                                value={gContext.goGetRegistrationData()?.personalData?.hearAboutUsValue}
                                                                onChange={option => {
                                                                    if (
                                                                        option.value === "Other"
                                                                    ) {
                                                                        setHearAboutUsAdditionalField(option.value)
                                                                    } else {
                                                                        setHearAboutUsAdditionalField(false)
                                                                    }
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            hearAboutUsValue: option,
                                                                            hearAboutUsOther: hearAboutUsAdditionalField ? "" : gContext.goGetRegistrationData()?.personalData?.hearAboutUsOther
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>

                                                    <Col sm={12} lg={6} className={`mx-auto ${hearAboutUsAdditionalField ? '' : ' other-providers-placeholder'}`}>
                                                        <Box mb={4} className="position-relative">
                                                            <Input type=""
                                                                name="hearAboutUsOther"
                                                                placeholder={constructHearAboutUsPlaceholder(values.hearAboutUsValue)}
                                                                style={{
                                                                    backgroundColor: hearAboutUsAdditionalField ? '#ffffff' : '#efefef',
                                                                    opacity: hearAboutUsAdditionalField ? '1' : '0.4'
                                                                }}
                                                                disabled={!hearAboutUsAdditionalField}
                                                                value={gContext.goGetRegistrationData()?.personalData?.hearAboutUsOther}
                                                                onChange={e => {
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            hearAboutUsOther: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                            />
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} lg={12} className="mt-3 pl-0 text-center">
                                                        <TinySwitch
                                                            setValue={
                                                                (e) => {
                                                                    goSetBusinessSwitch(e)
                                                                    gContext.goSetRegistrationData({
                                                                        ...gContext.goGetRegistrationData(),
                                                                        personalData: {
                                                                            ...gContext.goGetRegistrationData()?.personalData,
                                                                            businessSwitch: e
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            value={gContext.goGetRegistrationData()?.personalData?.businessSwitch}
                                                        />
                                                        <span style={{
                                                            position: 'relative',
                                                            bottom: '5px'
                                                        }}>I am a business user</span>
                                                    </Col>
                                                </Row>

                                                {
                                                    gContext.goGetRegistrationData()?.personalData?.businessSwitch &&
                                                    <>
                                                        <Row className="mt-4">
                                                            <Col sm={12} lg={6}>
                                                                <Box mb={4} className="position-relative">
                                                                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.companyName && <p className="invalid-label">Required field</p>}
                                                                    <Input
                                                                        type=""
                                                                        name="companyName"
                                                                        placeholder="Business Name"
                                                                        value={gContext.goGetRegistrationData()?.personalData?.companyName}
                                                                        onChange={e => {
                                                                            gContext.goSetRegistrationData({
                                                                                ...gContext.goGetRegistrationData(),
                                                                                personalData: {
                                                                                    ...gContext.goGetRegistrationData()?.personalData,
                                                                                    companyName: e.target.value
                                                                                }
                                                                            })
                                                                        }}
                                                                        css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                                    />
                                                                </Box>
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <Box mb={4} className="position-relative">
                                                                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.companyPosition && <p className="invalid-label">Required field</p>}
                                                                    <Input
                                                                        type=""
                                                                        name="companyPosition"
                                                                        placeholder="Company Role"
                                                                        value={gContext.goGetRegistrationData()?.personalData?.companyPosition}
                                                                        onChange={e => {
                                                                            gContext.goSetRegistrationData({
                                                                                ...gContext.goGetRegistrationData(),
                                                                                personalData: {
                                                                                    ...gContext.goGetRegistrationData()?.personalData,
                                                                                    companyPosition: e.target.value
                                                                                }
                                                                            })
                                                                        }}
                                                                        css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                                    />
                                                                </Box>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} lg={6}>
                                                                <Box mb={4} className="position-relative">
                                                                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.utrNumber && <p className="invalid-label">Required field</p>}
                                                                    <Input
                                                                        type=""
                                                                        name="utrNumber"
                                                                        placeholder="Company / UTR number"
                                                                        value={gContext.goGetRegistrationData()?.personalData?.utrNumber}
                                                                        onChange={e => {
                                                                            gContext.goSetRegistrationData({
                                                                                ...gContext.goGetRegistrationData(),
                                                                                personalData: {
                                                                                    ...gContext.goGetRegistrationData()?.personalData,
                                                                                    utrNumber: e.target.value
                                                                                }
                                                                            })
                                                                        }}
                                                                        css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                                    />
                                                                </Box>
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <Box mb={4} className="position-relative">
                                                                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.vat && <p className="invalid-label">Required field</p>}
                                                                    <Input
                                                                        type=""
                                                                        name="vat"
                                                                        placeholder="VAT"
                                                                        value={gContext.goGetRegistrationData()?.personalData?.vat}
                                                                        onChange={e => {
                                                                            gContext.goSetRegistrationData({
                                                                                ...gContext.goGetRegistrationData(),
                                                                                personalData: {
                                                                                    ...gContext.goGetRegistrationData()?.personalData,
                                                                                    vat: e.target.value
                                                                                }
                                                                            })
                                                                        }}
                                                                        css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                                                                    />
                                                                </Box>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }

                                                <Row>
                                                    <Col xs={12} className="m-auto pt-5">
                                                        <Row>
                                                            <Col
                                                                xs={2}
                                                                md={2}
                                                                lg={1}
                                                                className="pt-1 text-center text-lg-left"
                                                            >
                                                                <TinySwitch setValue={(e) => setMarketingConsent(e)} />
                                                            </Col>
                                                            <Col xs={10} md={10} lg={11} className="mb-4">
                                                                <span className="consent-info register-consent">
                                                                I am happy to hear from YouFibre by email, WhatsApp and SMS about YouFibre’s broadband packages
                                                                and any special offers. I am aware that I can opt-out at any time.
                                                                For details on how we manage your data, please see our&nbsp;
                                                                </span> 
                                                                <a
                                                                    target="_blank"
                                                                    href="/legal/privacy-and-cookie-policy"
                                                                    style={{ color: '#08DE9E', textDecoration: 'underline' }}
                                                                >
                                                                    <span className="consent-info register-consent" style={{ color: '#08DE9E' }}>
                                                                        privacy policy
                                                                    </span>
                                                                </a>
                                                                .
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row className="justify-content-center">
                                                    <Col sm={12} lg={12} className="mb-5 mt-3 text-center">
                                                        <BigRoundButton
                                                            className={`register-form-button ${canNotContinue() ? 'invalid' : ''} ${marketingConsent ? '' : ' grayed-out'}`}
                                                            title={
                                                                isCreatingRecords && !canNotContinue()
                                                                    ? 'Please wait...'
                                                                    : 'Register'
                                                            }
                                                            onClick={handleSubmit}
                                                            color={marketingConsent ? 'alternative' : 'black'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </>
    )
}

export default RegisterBduk;